/* eslint-disable max-statements */
import type {
  Key,
  Section,
} from "@lainnao/chord-progression-parser-bundler/generatedTypes";
import { ChordInfoView } from "./ChordInfoView";
import type { DegreeStyle } from "~/libs/chordProgressionUtil/DegreeStyle";
import { exhaustiveGuard } from "~/libs/exhaustiveGuard";

export type ChordBlocksViewProps = {
  readonly chordBlocks: Section["chordBlocks"];
  readonly degreeStyle: DegreeStyle;
};

export function ChordBlocksView({
  chordBlocks,
  degreeStyle,
}: ChordBlocksViewProps): JSX.Element {
  const gridPosition = {
    x: 0,
    y: 1,
  };

  let currentKey: Key | undefined;
  let maxColumnCount = 0;

  const chordBlocksContent = chordBlocks.flatMap(
    (chordBlock, chordBlockIndex) => {
      // maxColumnCountを更新
      if (gridPosition.x > maxColumnCount) {
        maxColumnCount = gridPosition.x;
      }

      switch (chordBlock.type) {
        case "br": {
          gridPosition.x = 0;
          gridPosition.y += 1;

          return [];
        }
        case "bar": {
          gridPosition.x += 1;
          const isLastBarOfLine =
            chordBlocks[chordBlockIndex + 1]?.type === "br" ||
            chordBlockIndex === chordBlocks.length - 1;

          return [
            <div
              key={chordBlockIndex}
              className="flex flex-nowrap"
              style={{
                gridColumn: gridPosition.x,
                gridRow: gridPosition.y,
              }}
            >
              {chordBlock.value.map((chordInfo, chordInfoIndex) => {
                const isFirstIndexInsideCurrentBar = chordInfoIndex === 0;
                const isLastIndexInsideCurrentBar =
                  chordInfoIndex === chordBlock.value.length - 1;

                const newKey = chordInfo.metaInfos.find(
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  (metaInfo) => metaInfo.type === "key",
                )?.value;

                if (newKey !== undefined) {
                  currentKey = newKey;
                }

                return (
                  <div
                    key={`${chordBlockIndex}-${chordInfoIndex}`}
                    className="flex flex-1 whitespace-nowrap text-sm"
                  >
                    {!isFirstIndexInsideCurrentBar && (
                      <div className="pointer-events-none flex select-none items-center">
                        ,
                      </div>
                    )}
                    <div className="w-full">
                      <ChordInfoView
                        chordInfo={chordInfo}
                        currentKey={currentKey}
                        degreeStyle={degreeStyle}
                        chordBorder={{
                          left: isFirstIndexInsideCurrentBar,
                          right: isLastIndexInsideCurrentBar && isLastBarOfLine,
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>,
          ];
        }
        default: {
          exhaustiveGuard(chordBlock);
          return [];
        }
      }
    },
  );

  return (
    <div
      className="grid w-full gap-y-1"
      style={{
        gridTemplateColumns: `repeat(${maxColumnCount}, 1fr)`,
      }}
    >
      {chordBlocksContent}
    </div>
  );
}
