import type { Ast } from "@lainnao/chord-progression-parser-bundler/generatedTypes";
import kagomeKagomeChordProgression from "./chordProgressions/kagomeKagome";
import bachAriaOnGStringChordProgression from "./chordProgressions/bachAriaOnGString";
import kagomeKagomeAst from "./asts/kagomeKagome";
import bachAriaOnGStringAst from "./asts/bachAriaOnGString";

// NOTE: MEMO.mdも見てください
export const chordProgressionAstFixtures = {
  bachAriaOnGString: {
    artistName: "Johann Sebastian Bach",
    trackName: "Aria on the G String",
    chordProgressionString: bachAriaOnGStringChordProgression,
    parsedAst: bachAriaOnGStringAst as Ast,
  },
  kagomeKagome: {
    artistName: "?",
    trackName: "かごめかごめ",
    chordProgressionString: kagomeKagomeChordProgression,
    parsedAst: kagomeKagomeAst as Ast,
  },
} as const satisfies Record<
  string,
  {
    artistName: string;
    trackName: string;
    chordProgressionString: string;
    parsedAst: Ast;
  }
>;
