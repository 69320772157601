import { match } from "ts-pattern";
import type { ToneNumber } from "./ToneNumber";
import type { FlatNormalizedDegree } from "./FlatNormalizedDegree";

export function convertToneNumberToFlatNormalizedDegree(
  toneNumber: ToneNumber,
): FlatNormalizedDegree {
  return match<ToneNumber, FlatNormalizedDegree>(toneNumber)
    .with(1, () => "1")
    .with(2, () => "2b")
    .with(3, () => "2")
    .with(4, () => "3b")
    .with(5, () => "3")
    .with(6, () => "4")
    .with(7, () => "5b")
    .with(8, () => "5")
    .with(9, () => "6b")
    .with(10, () => "6")
    .with(11, () => "7b")
    .with(12, () => "7")
    .exhaustive();
}
