/* eslint-disable max-lines */
/* eslint-disable unicorn/no-null */
const ast = [
  {
    chordBlocks: [
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "D",
                  chordType: "M",
                  extensions: [],
                },
                plain: "D",
              },
            },
            denominator: null,
            metaInfos: [
              {
                type: "key",
                value: "D",
              },
            ],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "D",
                  chordType: "M",
                  extensions: [],
                },
                plain: "D",
              },
            },
            denominator: "C#",
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "B",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Bm",
              },
            },
            denominator: null,
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "B",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Bm",
              },
            },
            denominator: "A",
            metaInfos: [],
          },
        ],
      },
      {
        type: "br",
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "G",
                  chordType: "M",
                  extensions: [],
                },
                plain: "G",
              },
            },
            denominator: null,
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "E",
                  chordType: "M",
                  extensions: [],
                },
                plain: "E",
              },
            },
            denominator: "G#",
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "M",
                  extensions: [],
                },
                plain: "A",
              },
            },
            denominator: null,
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "M",
                  extensions: ["7"],
                },
                plain: "A(7)",
              },
            },
            denominator: "G",
            metaInfos: [],
          },
        ],
      },
      {
        type: "br",
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: "#",
                  base: "F",
                  chordType: "m",
                  extensions: ["7", "b5"],
                },
                plain: "F#m(7,b5)",
              },
            },
            denominator: null,
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: "#",
                  base: "F",
                  chordType: "m",
                  extensions: ["7", "b5"],
                },
                plain: "F#m(7,b5)",
              },
            },
            denominator: "E",
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "B",
                  chordType: "M",
                  extensions: ["7"],
                },
                plain: "B(7)",
              },
            },
            denominator: "D#",
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "B",
                  chordType: "M",
                  extensions: ["7"],
                },
                plain: "B(7)",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
      {
        type: "br",
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "E",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Em",
              },
            },
            denominator: null,
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "E",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Em",
              },
            },
            denominator: "D",
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "M",
                  extensions: ["7"],
                },
                plain: "A(7)",
              },
            },
            denominator: "C#",
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "M",
                  extensions: ["7"],
                },
                plain: "A(7)",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
      {
        type: "br",
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "D",
                  chordType: "M",
                  extensions: [],
                },
                plain: "D",
              },
            },
            denominator: null,
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "D",
                  chordType: "M",
                  extensions: [],
                },
                plain: "D",
              },
            },
            denominator: "C#",
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "B",
                  chordType: "m",
                  extensions: ["7"],
                },
                plain: "Bm(7)",
              },
            },
            denominator: null,
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "E",
                  chordType: "M",
                  extensions: ["7"],
                },
                plain: "E(7)",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
      {
        type: "br",
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "M",
                  extensions: [],
                },
                plain: "A",
              },
            },
            denominator: null,
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "B",
                  chordType: "m",
                  extensions: ["7"],
                },
                plain: "Bm(7)",
              },
            },
            denominator: "D",
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "E",
                  chordType: "M",
                  extensions: ["7", "sus4"],
                },
                plain: "E(7,sus4)",
              },
            },
            denominator: null,
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "E",
                  chordType: "M",
                  extensions: ["7"],
                },
                plain: "E(7)",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "M",
                  extensions: [],
                },
                plain: "A",
              },
            },
            denominator: null,
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "M",
                  extensions: ["7"],
                },
                plain: "A(7)",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
    ],
    metaInfos: [
      {
        type: "section",
        value: "A",
      },
    ],
  },
  {
    chordBlocks: [
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "D",
                  chordType: "M",
                  extensions: [],
                },
                plain: "D",
              },
            },
            denominator: null,
            metaInfos: [
              {
                type: "key",
                value: "D",
              },
            ],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "D",
                  chordType: "M",
                  extensions: [],
                },
                plain: "D",
              },
            },
            denominator: "C#",
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "B",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Bm",
              },
            },
            denominator: null,
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "B",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Bm",
              },
            },
            denominator: "A",
            metaInfos: [],
          },
        ],
      },
      {
        type: "br",
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "G",
                  chordType: "M",
                  extensions: [],
                },
                plain: "G",
              },
            },
            denominator: null,
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "E",
                  chordType: "M",
                  extensions: [],
                },
                plain: "E",
              },
            },
            denominator: "G#",
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "M",
                  extensions: [],
                },
                plain: "A",
              },
            },
            denominator: null,
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "M",
                  extensions: ["7"],
                },
                plain: "A(7)",
              },
            },
            denominator: "G",
            metaInfos: [],
          },
        ],
      },
      {
        type: "br",
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: "#",
                  base: "F",
                  chordType: "m",
                  extensions: ["7", "b5"],
                },
                plain: "F#m(7,b5)",
              },
            },
            denominator: null,
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: "#",
                  base: "F",
                  chordType: "m",
                  extensions: ["7", "b5"],
                },
                plain: "F#m(7,b5)",
              },
            },
            denominator: "E",
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "B",
                  chordType: "M",
                  extensions: ["7"],
                },
                plain: "B(7)",
              },
            },
            denominator: "D#",
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "B",
                  chordType: "M",
                  extensions: ["7"],
                },
                plain: "B(7)",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
      {
        type: "br",
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "E",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Em",
              },
            },
            denominator: null,
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "E",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Em",
              },
            },
            denominator: "D",
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "M",
                  extensions: ["7"],
                },
                plain: "A(7)",
              },
            },
            denominator: "C#",
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "M",
                  extensions: ["7"],
                },
                plain: "A(7)",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
      {
        type: "br",
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "D",
                  chordType: "M",
                  extensions: [],
                },
                plain: "D",
              },
            },
            denominator: null,
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "D",
                  chordType: "M",
                  extensions: [],
                },
                plain: "D",
              },
            },
            denominator: "C#",
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "B",
                  chordType: "m",
                  extensions: ["7"],
                },
                plain: "Bm(7)",
              },
            },
            denominator: null,
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "E",
                  chordType: "M",
                  extensions: ["7"],
                },
                plain: "E(7)",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
      {
        type: "br",
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "M",
                  extensions: [],
                },
                plain: "A",
              },
            },
            denominator: null,
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "B",
                  chordType: "m",
                  extensions: ["7"],
                },
                plain: "Bm(7)",
              },
            },
            denominator: "D",
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "E",
                  chordType: "M",
                  extensions: ["7", "sus4"],
                },
                plain: "E(7,sus4)",
              },
            },
            denominator: null,
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "E",
                  chordType: "M",
                  extensions: ["7"],
                },
                plain: "E(7)",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "M",
                  extensions: [],
                },
                plain: "A",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
    ],
    metaInfos: [
      {
        type: "section",
        value: "A'",
      },
    ],
  },
  {
    chordBlocks: [
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "M",
                  extensions: [],
                },
                plain: "A",
              },
            },
            denominator: null,
            metaInfos: [
              {
                type: "key",
                value: "A",
              },
            ],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "M",
                  extensions: [],
                },
                plain: "A",
              },
            },
            denominator: "G",
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: "#",
                  base: "F",
                  chordType: "m",
                  extensions: ["7", "b5"],
                },
                plain: "F#m(7,b5)",
              },
            },
            denominator: null,
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: "#",
                  base: "F",
                  chordType: "m",
                  extensions: ["7", "b5"],
                },
                plain: "F#m(7,b5)",
              },
            },
            denominator: "E",
            metaInfos: [],
          },
        ],
      },
      {
        type: "br",
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "B",
                  chordType: "M",
                  extensions: ["7"],
                },
                plain: "B(7)",
              },
            },
            denominator: "D#",
            metaInfos: [
              {
                type: "key",
                value: "G",
              },
            ],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "B",
                  chordType: "M",
                  extensions: ["7"],
                },
                plain: "B(7)",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "E",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Em",
              },
            },
            denominator: null,
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "E",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Em",
              },
            },
            denominator: "D",
            metaInfos: [],
          },
        ],
      },
      {
        type: "br",
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: "#",
                  base: "C",
                  chordType: "m",
                  extensions: ["7", "b5"],
                },
                plain: "C#m(7,b5)",
              },
            },
            denominator: null,
            metaInfos: [
              {
                type: "key",
                value: "D",
              },
            ],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: "#",
                  base: "C",
                  chordType: "m",
                  extensions: ["7", "b5"],
                },
                plain: "C#m(7,b5)",
              },
            },
            denominator: "B",
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: "#",
                  base: "F",
                  chordType: "M",
                  extensions: [],
                },
                plain: "F#",
              },
            },
            denominator: "A#",
            metaInfos: [],
          },
        ],
      },
      {
        type: "br",
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "B",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Bm",
              },
            },
            denominator: null,
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: "#",
                  base: "C",
                  chordType: "m",
                  extensions: ["7", "b5"],
                },
                plain: "C#m(7,b5)",
              },
            },
            denominator: null,
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: "#",
                  base: "C",
                  chordType: "m",
                  extensions: ["7", "b5"],
                },
                plain: "C#m(7,b5)",
              },
            },
            denominator: "G",
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: "#",
                  base: "F",
                  chordType: "M",
                  extensions: ["7", "#5"],
                },
                plain: "F#(7,#5)",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "B",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Bm",
              },
            },
            denominator: null,
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "B",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Bm",
              },
            },
            denominator: "A",
            metaInfos: [],
          },
        ],
      },
      {
        type: "br",
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: "#",
                  base: "G",
                  chordType: "m",
                  extensions: ["7", "b5"],
                },
                plain: "G#m(7,b5)",
              },
            },
            denominator: null,
            metaInfos: [
              {
                type: "key",
                value: "A",
              },
            ],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "D",
                  chordType: "M",
                  extensions: [],
                },
                plain: "D",
              },
            },
            denominator: "F#",
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "E",
                  chordType: "M",
                  extensions: [],
                },
                plain: "E",
              },
            },
            denominator: null,
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "E",
                  chordType: "M",
                  extensions: [],
                },
                plain: "E",
              },
            },
            denominator: "D",
            metaInfos: [],
          },
        ],
      },
      {
        type: "br",
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "M",
                  extensions: [],
                },
                plain: "A",
              },
            },
            denominator: "C#",
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "M",
                  extensions: [],
                },
                plain: "A",
              },
            },
            denominator: "C#",
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "B",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Bm",
              },
            },
            denominator: "D",
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "E",
                  chordType: "M",
                  extensions: ["7"],
                },
                plain: "E(7)",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "M",
                  extensions: [],
                },
                plain: "A",
              },
            },
            denominator: null,
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "M",
                  extensions: [],
                },
                plain: "A",
              },
            },
            denominator: "G",
            metaInfos: [],
          },
        ],
      },
      {
        type: "br",
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "D",
                  chordType: "M",
                  extensions: [],
                },
                plain: "D",
              },
            },
            denominator: "F#",
            metaInfos: [
              {
                type: "key",
                value: "D",
              },
            ],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "G",
                  chordType: "M",
                  extensions: ["M7"],
                },
                plain: "G(M7)",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "E",
                  chordType: "M",
                  extensions: [],
                },
                plain: "E",
              },
            },
            denominator: "G#",
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "M",
                  extensions: ["7"],
                },
                plain: "A(7)",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
      {
        type: "br",
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: "#",
                  base: "F",
                  chordType: "M",
                  extensions: ["7"],
                },
                plain: "F#(7)",
              },
            },
            denominator: "A#",
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "B",
                  chordType: "M",
                  extensions: ["7"],
                },
                plain: "B(7)",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "E",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Em",
              },
            },
            denominator: null,
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "E",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Em",
              },
            },
            denominator: "D",
            metaInfos: [],
          },
        ],
      },
      {
        type: "br",
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "M",
                  extensions: ["7"],
                },
                plain: "A(7)",
              },
            },
            denominator: "C#",
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "M",
                  extensions: ["7"],
                },
                plain: "A(7)",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "D",
                  chordType: "M",
                  extensions: [],
                },
                plain: "D",
              },
            },
            denominator: null,
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "D",
                  chordType: "M",
                  extensions: [],
                },
                plain: "D",
              },
            },
            denominator: "C",
            metaInfos: [],
          },
        ],
      },
      {
        type: "br",
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "E",
                  chordType: "m",
                  extensions: ["7"],
                },
                plain: "Em(7)",
              },
            },
            denominator: "B",
            metaInfos: [
              {
                type: "key",
                value: "G",
              },
            ],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "D",
                  chordType: "M",
                  extensions: ["7"],
                },
                plain: "D(7)",
              },
            },
            denominator: "A",
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "G",
                  chordType: "M",
                  extensions: [],
                },
                plain: "G",
              },
            },
            denominator: null,
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "G",
                  chordType: "M",
                  extensions: [],
                },
                plain: "G",
              },
            },
            denominator: "F#",
            metaInfos: [],
          },
        ],
      },
      {
        type: "br",
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: "#",
                  base: "C",
                  chordType: "m",
                  extensions: ["7", "b5"],
                },
                plain: "C#m(7,b5)",
              },
            },
            denominator: "E",
            metaInfos: [
              {
                type: "key",
                value: "D",
              },
            ],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "E",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Em",
              },
            },
            denominator: "D",
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "M",
                  extensions: ["7"],
                },
                plain: "A(7)",
              },
            },
            denominator: "C#",
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "M",
                  extensions: ["7"],
                },
                plain: "A(7)",
              },
            },
            denominator: "C#",
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "D",
                  chordType: "M",
                  extensions: [],
                },
                plain: "D",
              },
            },
            denominator: null,
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "E",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Em",
              },
            },
            denominator: "D",
            metaInfos: [],
          },
        ],
      },
      {
        type: "br",
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "D",
                  chordType: "M",
                  extensions: [],
                },
                plain: "D",
              },
            },
            denominator: "A",
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "M",
                  extensions: ["7"],
                },
                plain: "A(7)",
              },
            },
            denominator: "G",
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "M",
                  extensions: ["7"],
                },
                plain: "A(7)",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "D",
                  chordType: "M",
                  extensions: [],
                },
                plain: "D",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
    ],
    metaInfos: [
      {
        type: "section",
        value: "B",
      },
    ],
  },
];
export default ast;
