import type { Section } from "@lainnao/chord-progression-parser-bundler/generatedTypes";
import { SectionMetaInfosView } from "./SectionMetaInfosView";
import { ChordBlocksView } from "./ChordBlocksView";
import type { DegreeStyle } from "~/libs/chordProgressionUtil/DegreeStyle";

export type SectionViewProps = {
  readonly section: Section;
  readonly degreeStyle: DegreeStyle;
};

export function SectionView({
  section,
  degreeStyle,
}: SectionViewProps): JSX.Element {
  return (
    <div className="flex w-fit gap-4">
      <SectionMetaInfosView sectionMetaList={section.metaInfos} />
      <ChordBlocksView
        chordBlocks={section.chordBlocks}
        degreeStyle={degreeStyle}
      />
    </div>
  );
}
