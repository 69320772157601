import type { SectionMeta } from "@lainnao/chord-progression-parser-bundler/generatedTypes";

export type SectionMetaInfosViewProps = {
  readonly sectionMetaList: SectionMeta[];
};

export function SectionMetaInfosView({
  sectionMetaList,
}: SectionMetaInfosViewProps): JSX.Element {
  const sectionName = sectionMetaList.find(
    (sectionMeta) => sectionMeta.type === "section",
  );

  return <div>{sectionName ? sectionName.value : "?"}</div>;
}
