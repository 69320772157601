import {
  Accidental,
  Base,
  type Key,
} from "@lainnao/chord-progression-parser-bundler/generatedTypes";
import type { FlatNormalizedDegree } from "./FlatNormalizedDegree";
import { getFlatNormalizedDegree } from "./getFlatNormalizedDegree";

function convertStringToAccidental(s: string): Accidental | undefined {
  switch (s) {
    case "#": {
      return Accidental.Sharp;
    }
    case "b": {
      return Accidental.Flat;
    }
    default: {
      return undefined;
    }
  }
}

export function parseFlatNormalizedDegreeFromChordString({
  chordString,
  key,
}: {
  chordString: string;
  key: Key;
}): FlatNormalizedDegree | undefined {
  const base: Base | undefined =
    Base[(chordString[0] ?? "") as keyof typeof Base];

  const accidental = chordString[1]
    ? convertStringToAccidental(chordString[1])
    : undefined;

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, @typescript-eslint/strict-boolean-expressions
  if (!base) {
    return undefined;
  }

  return getFlatNormalizedDegree({
    key,
    base,
    accidental,
  });
}
