import type { ChordProgressionAstViewProps } from ".";
import { chordProgressionAstFixtures } from "~/fixtures/chordProgressionAstFixtures";

function getDefaultProps(): ChordProgressionAstViewProps {
  return {
    ast: chordProgressionAstFixtures.bachAriaOnGString.parsedAst,
    degreeStyle: "number",
  };
}

export const chordProgressionAstViewFixtures = {
  getDefaultProps,
};
