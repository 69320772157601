import { clsx } from "clsx";
import type { ChordFunction } from "~/libs/chordProgressionUtil/ChordFunction";

export type LeftRightBool = {
  readonly left: boolean;
  readonly right: boolean;
};

export type ChordViewWrapperProps = {
  readonly chordBorder: LeftRightBool;
  readonly headElement: React.ReactNode;
  readonly chordFunction?: ChordFunction | undefined;
  readonly chordElement: React.ReactNode;
};

export function ChordViewWrapper({
  chordBorder,
  headElement,
  chordFunction,
  chordElement,
}: ChordViewWrapperProps): JSX.Element {
  return (
    <div>
      <div className="h-4">{headElement}</div>

      <div
        className={clsx("border-primary-500 px-3", {
          "border-l-2": chordBorder.left,
          "border-r-2": chordBorder.right,
          "bg-primary-100": chordFunction === "S",
          "bg-primary-300": chordFunction === "D",
        })}
      >
        {chordElement}
      </div>
    </div>
  );
}
