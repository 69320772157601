import { match } from "ts-pattern";
import {
  Base,
  Accidental,
} from "@lainnao/chord-progression-parser-bundler/generatedTypes";
import type { ToneNumber } from "./ToneNumber";

// eslint-disable-next-line max-lines-per-function
export function getToneNumber({
  base,
  accidental,
}: {
  base: Base;
  accidental: Accidental | undefined;
}): ToneNumber {
  return (
    match<[Base, Accidental | undefined], ToneNumber>([base, accidental])
      // C
      .with([Base.B, Accidental.Sharp], () => 1)
      .with([Base.C, undefined], () => 1)
      // C#
      .with([Base.C, Accidental.Sharp], () => 2)
      .with([Base.D, Accidental.Flat], () => 3)
      // D
      .with([Base.D, undefined], () => 3)
      // D#
      .with([Base.D, Accidental.Sharp], () => 4)
      .with([Base.E, Accidental.Flat], () => 4)
      // E
      .with([Base.E, undefined], () => 5)
      .with([Base.F, Accidental.Flat], () => 5)
      // F
      .with([Base.E, Accidental.Sharp], () => 6)
      .with([Base.F, undefined], () => 6)
      // F#
      .with([Base.F, Accidental.Sharp], () => 7)
      .with([Base.G, Accidental.Flat], () => 7)
      // G
      .with([Base.G, undefined], () => 8)
      // G#
      .with([Base.G, Accidental.Sharp], () => 9)
      .with([Base.A, Accidental.Flat], () => 10)
      // A
      .with([Base.A, undefined], () => 10)
      // A#
      .with([Base.A, Accidental.Sharp], () => 11)
      .with([Base.B, Accidental.Flat], () => 11)
      // B
      .with([Base.B, undefined], () => 12)
      .with([Base.C, Accidental.Flat], () => 12)
      .run()
  );
}
