const chordProgression = `
@section=A
[key=D]D, D/C# - Bm, Bm/A
G, E/G# - A, A(7)/G
F#m(7,b5), F#m(7,b5)/E - B(7)/D#, B(7)
Em, Em/D - A(7)/C#, A(7)
D, D/C# - Bm(7), E(7)
A,Bm(7)/D,E(7,sus4), E(7) - A, A(7)

@section=A'
[key=D]D, D/C# - Bm, Bm/A
G, E/G# - A, A(7)/G
F#m(7,b5), F#m(7,b5)/E - B(7)/D#, B(7)
Em, Em/D - A(7)/C#, A(7)
D, D/C# - Bm(7), E(7)
A,Bm(7)/D,E(7,sus4), E(7) - A

@section=B
[key=A]A, A/G - F#m(7,b5), F#m(7,b5)/E
[key=G]B(7)/D#, B(7) - Em, Em/D
[key=D]C#m(7,b5), C#m(7,b5)/B - F#/A#
Bm, C#m(7,b5), C#m(7,b5)/G, F#(7,#5) - Bm, Bm/A
[key=A]G#m(7,b5), D/F# - E, E/D
A/C#, A/C#, Bm/D, E(7) - A, A/G
[key=D]D/F#, G(M7) - E/G#, A(7)
F#(7)/A#, B(7) - Em, Em/D
A(7)/C#, A(7) - D, D/C
[key=G]Em(7)/B, D(7)/A - G, G/F#
[key=D]C#m(7,b5)/E, Em/D - A(7)/C#, A(7)/C#, D, Em/D
D/A, A(7)/G, A(7) - D
`;

export default chordProgression;
