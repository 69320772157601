import {
  ChordType,
  type Key,
  type ChordInfo,
} from "@lainnao/chord-progression-parser-bundler/generatedTypes";
import { KeyView } from "./KeyView";
import { ChordView } from "./ChordView";
import { ChordViewWrapper, type LeftRightBool } from "./ChordViewWrapper";
import { getFunctionByDegreeAndChordType } from "~/libs/chordProgressionUtil/getFunctionByDegreeAndChordType";
import { convertFlatNormalizedDegreeToRoman } from "~/libs/chordProgressionUtil/convertFlatNormalizedDegreeToRoman";
import type { DegreeStyle } from "~/libs/chordProgressionUtil/DegreeStyle";
import { getFlatNormalizedDegree } from "~/libs/chordProgressionUtil/getFlatNormalizedDegree";
import { parseFlatNormalizedDegreeFromChordString } from "~/libs/chordProgressionUtil/parseFlatNormalizedDegreeFromChordString";

export type ChordInfoViewProps = {
  readonly chordInfo: ChordInfo;
  readonly currentKey: Key | undefined;
  readonly degreeStyle: DegreeStyle;
  readonly chordBorder: LeftRightBool;
};

// eslint-disable-next-line max-statements, complexity
export function ChordInfoView({
  chordInfo,
  currentKey,
  chordBorder,
  degreeStyle,
}: ChordInfoViewProps): JSX.Element {
  const key = chordInfo.metaInfos.find(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (metaInfo) => metaInfo.type === "key",
  );

  switch (chordInfo.chordExpression.type) {
    case "unIdentified": {
      return (
        <ChordViewWrapper
          chordBorder={chordBorder}
          headElement={key ? <KeyView value={key.value} /> : undefined}
          chordElement={
            <ChordView
              chord={{
                numerator: "?",
                denominator: undefined,
              }}
              degreeStyle={degreeStyle}
              degree={{
                numerator: "?",
                denominator: undefined,
              }}
            />
          }
        />
      );
    }
    case "noChord": {
      return (
        <ChordViewWrapper
          chordBorder={chordBorder}
          headElement={key ? <KeyView value={key.value} /> : undefined}
          chordElement={
            <ChordView
              chord={{
                numerator: "_",
                denominator: undefined,
              }}
              degreeStyle={degreeStyle}
              degree={{
                numerator: "_",
                denominator: undefined,
              }}
            />
          }
        />
      );
    }
    case "same": {
      const degreeDenominator =
        !!chordInfo.denominator && !!currentKey
          ? parseFlatNormalizedDegreeFromChordString({
              chordString: chordInfo.denominator,
              key: currentKey,
            })
          : undefined;

      const convertedDegreeDenominator = degreeDenominator
        ? degreeStyle === "roman"
          ? convertFlatNormalizedDegreeToRoman(degreeDenominator)
          : degreeDenominator
        : undefined;

      return (
        <ChordViewWrapper
          chordBorder={chordBorder}
          headElement={key ? <KeyView value={key.value} /> : undefined}
          //TODO: chordFunction
          chordElement={
            <ChordView
              chord={{
                numerator: "%",
                denominator: chordInfo.denominator,
              }}
              degreeStyle={degreeStyle}
              degree={{
                numerator: "%",
                denominator: convertedDegreeDenominator,
              }}
            />
          }
        />
      );
    }
    case "chord": {
      const chord = chordInfo.chordExpression.value;

      const flatNormalizedDegree =
        currentKey === undefined
          ? undefined
          : getFlatNormalizedDegree({
              key: currentKey,
              base: chord.detailed.base,
              accidental: chord.detailed.accidental ?? undefined,
            });

      const convertedDegreeNumerator =
        (flatNormalizedDegree
          ? degreeStyle === "roman"
            ? convertFlatNormalizedDegreeToRoman(flatNormalizedDegree)
            : flatNormalizedDegree
          : "") +
        (chord.detailed.chordType === ChordType.Major
          ? ""
          : chord.detailed.chordType);

      const degreeDenominator =
        !!chordInfo.denominator && !!currentKey
          ? parseFlatNormalizedDegreeFromChordString({
              chordString: chordInfo.denominator,
              key: currentKey,
            })
          : undefined;

      const convertedDegreeDenominator = degreeDenominator
        ? degreeStyle === "roman"
          ? convertFlatNormalizedDegreeToRoman(degreeDenominator)
          : degreeDenominator
        : undefined;

      return (
        <ChordViewWrapper
          chordBorder={chordBorder}
          headElement={key ? <KeyView value={key.value} /> : undefined}
          chordFunction={
            flatNormalizedDegree
              ? getFunctionByDegreeAndChordType({
                  degree: flatNormalizedDegree,
                  chordType: chord.detailed.chordType,
                })
              : undefined
          }
          chordElement={
            <ChordView
              chord={{
                numerator: chord.plain,
                denominator: chordInfo.denominator,
              }}
              degreeStyle={degreeStyle}
              degree={{
                numerator: convertedDegreeNumerator,
                denominator: convertedDegreeDenominator,
              }}
            />
          }
        />
      );
    }
    default: {
      //TODO: exhaustive check
      throw new Error("exhaustive check");
    }
  }
}
