import { match } from "ts-pattern";
import { Key } from "@lainnao/chord-progression-parser-bundler/generatedTypes";
import type { ToneNumber } from "./ToneNumber";

export function convertKeyToToneNumber({
  key,
}: {
  key: Key;
}): ToneNumber | undefined {
  return match<Key, ToneNumber | undefined>(key)
    .with(Key.C_M, () => 1)
    .with(Key.C_m, () => 1)
    .with(Key.Cs_M, () => 2)
    .with(Key.Cs_m, () => 2)
    .with(Key.Db_M, () => 2)
    .with(Key.Db_m, () => 2)
    .with(Key.D_M, () => 3)
    .with(Key.D_m, () => 3)
    .with(Key.Ds_M, () => 4)
    .with(Key.Ds_m, () => 4)
    .with(Key.Eb_M, () => 4)
    .with(Key.Eb_m, () => 4)
    .with(Key.E_M, () => 5)
    .with(Key.E_m, () => 5)
    .with(Key.Fb_M, () => 5)
    .with(Key.Fb_m, () => 5)
    .with(Key.F_M, () => 6)
    .with(Key.F_m, () => 6)
    .with(Key.Fs_M, () => 7)
    .with(Key.Fs_m, () => 7)
    .with(Key.Gb_M, () => 7)
    .with(Key.Gb_m, () => 7)
    .with(Key.G_M, () => 8)
    .with(Key.G_m, () => 8)
    .with(Key.Gs_M, () => 9)
    .with(Key.Gs_m, () => 9)
    .with(Key.Ab_M, () => 9)
    .with(Key.Ab_m, () => 9)
    .with(Key.A_M, () => 10)
    .with(Key.A_m, () => 10)
    .with(Key.As_M, () => 11)
    .with(Key.As_m, () => 11)
    .with(Key.Bb_M, () => 11)
    .with(Key.Bb_m, () => 11)
    .with(Key.B_M, () => 12)
    .with(Key.B_m, () => 12)
    .with(Key.Cb_M, () => 12)
    .with(Key.Cb_m, () => 12)
    .with(Key.UnIdentified, () => undefined)
    .exhaustive();
}
