import type {
  Key,
  Base,
  Accidental,
} from "@lainnao/chord-progression-parser-bundler/generatedTypes";
import type { FlatNormalizedDegree } from "./FlatNormalizedDegree";
import { convertKeyToToneNumber } from "./convertKeyToToneNumber";
import { getToneNumber } from "./getToneNumber";
import { convertToneNumberToFlatNormalizedDegree } from "./convertToneNumberToFlatNormalizedDegree";
import type { ToneNumber } from "./ToneNumber";

function getRelativeToneNumber({
  keyToneNumber,
  chordToneNumber,
}: {
  keyToneNumber: number;
  chordToneNumber: number;
}): ToneNumber {
  const distance = chordToneNumber - keyToneNumber;
  return ((distance < 0
    ? chordToneNumber - keyToneNumber + 12
    : chordToneNumber - keyToneNumber) + 1) as ToneNumber;
}

export function getFlatNormalizedDegree({
  key,
  base,
  accidental,
}: {
  key: Key;
  base: Base;
  accidental: Accidental | undefined;
}): FlatNormalizedDegree | undefined {
  const keyToneNumber = convertKeyToToneNumber({
    key,
  });
  if (!keyToneNumber) {
    return undefined;
  }

  const chordToneNumber = getToneNumber({
    base,
    accidental,
  });

  const relativeToneNumber = getRelativeToneNumber({
    keyToneNumber,
    chordToneNumber,
  });

  return convertToneNumberToFlatNormalizedDegree(relativeToneNumber);
}
