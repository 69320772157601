import { clsx } from "clsx";
import type { DegreeStyle } from "~/libs/chordProgressionUtil/DegreeStyle";

type ChordViewProps = {
  readonly chord: {
    numerator: string;
    denominator: string | undefined;
  };
  readonly degreeStyle: DegreeStyle;
  readonly degree:
    | {
        numerator: string;
        denominator: string | undefined;
      }
    | undefined;
};

export function ChordView({
  chord,
  degree,
  degreeStyle,
}: ChordViewProps): JSX.Element {
  return (
    <>
      <div className="flex flex-nowrap font-mono">
        <span>{chord.numerator}</span>
        {!!chord.denominator && (
          <>
            <span>/</span>
            <span>{chord.denominator}</span>
          </>
        )}
      </div>

      {/* ディグリー */}
      <div
        className={clsx("flex flex-nowrap", {
          "font-mono": degreeStyle === "number",
          "font-serif": degreeStyle === "roman",
        })}
      >
        <span>{degree?.numerator}</span>
        {!!degree?.denominator && (
          <>
            <span>/</span>
            <span>{degree.denominator}</span>
          </>
        )}
      </div>
    </>
  );
}
