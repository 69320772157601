import { match } from "ts-pattern";
import type {
  FlatNormalizedDegree,
  FlatNormalizedDegreeRoman,
} from "./FlatNormalizedDegree";

export function convertFlatNormalizedDegreeToRoman(
  value: FlatNormalizedDegree,
): FlatNormalizedDegreeRoman {
  return match<FlatNormalizedDegree, FlatNormalizedDegreeRoman>(value)
    .with("1", () => "I")
    .with("2b", () => "IIb")
    .with("2", () => "II")
    .with("3b", () => "IIIb")
    .with("3", () => "III")
    .with("4", () => "IV")
    .with("5b", () => "Vb")
    .with("5", () => "V")
    .with("6b", () => "VIb")
    .with("6", () => "VI")
    .with("7b", () => "VIIb")
    .with("7", () => "VII")
    .exhaustive();
}
