import { match } from "ts-pattern";
import { ChordType } from "@lainnao/chord-progression-parser-bundler/generatedTypes";
import type { ChordFunction } from "./ChordFunction";
import type { FlatNormalizedDegree } from "./FlatNormalizedDegree";

export function getFunctionByDegreeAndChordType({
  degree,
  chordType,
}: {
  degree: FlatNormalizedDegree;
  chordType: ChordType;
}): ChordFunction {
  return (
    match<[FlatNormalizedDegree, ChordType], ChordFunction>([degree, chordType])
      //
      .with(["1", ChordType.Major], () => "T")
      .with(["1", ChordType.Minor], () => "S")
      .with(["1", ChordType.Diminished], () => "D")
      .with(["1", ChordType.Augmented], () => "S")
      //
      .with(["2b", ChordType.Major], () => "D")
      .with(["2b", ChordType.Minor], () => "D")
      .with(["2b", ChordType.Diminished], () => "D")
      .with(["2b", ChordType.Augmented], () => "D")
      //
      .with(["2", ChordType.Major], () => "S")
      .with(["2", ChordType.Minor], () => "S")
      .with(["2", ChordType.Diminished], () => "D")
      .with(["2", ChordType.Augmented], () => "S")
      //
      .with(["3b", ChordType.Major], () => "T")
      .with(["3b", ChordType.Minor], () => "T")
      .with(["3b", ChordType.Diminished], () => "D")
      .with(["3b", ChordType.Augmented], () => "T")
      //
      .with(["3", ChordType.Major], () => "D")
      .with(["3", ChordType.Minor], () => "T")
      .with(["3", ChordType.Diminished], () => "D")
      .with(["3", ChordType.Augmented], () => "D")
      //
      .with(["4", ChordType.Major], () => "S")
      .with(["4", ChordType.Minor], () => "S")
      .with(["4", ChordType.Diminished], () => "D")
      .with(["4", ChordType.Augmented], () => "S")
      //
      .with(["5b", ChordType.Major], () => "S")
      .with(["5b", ChordType.Minor], () => "S")
      .with(["5b", ChordType.Diminished], () => "D")
      .with(["5b", ChordType.Augmented], () => "S")
      //
      .with(["5", ChordType.Major], () => "D")
      .with(["5", ChordType.Minor], () => "S")
      .with(["5", ChordType.Diminished], () => "D")
      .with(["5", ChordType.Augmented], () => "D")
      //
      .with(["6b", ChordType.Major], () => "S")
      .with(["6b", ChordType.Minor], () => "S")
      .with(["6b", ChordType.Diminished], () => "D")
      .with(["6b", ChordType.Augmented], () => "S")
      //
      .with(["6", ChordType.Major], () => "T")
      .with(["6", ChordType.Minor], () => "T")
      .with(["6", ChordType.Diminished], () => "D")
      .with(["6", ChordType.Augmented], () => "S")
      //
      .with(["7b", ChordType.Major], () => "D")
      .with(["7b", ChordType.Minor], () => "D")
      .with(["7b", ChordType.Diminished], () => "D")
      .with(["7b", ChordType.Augmented], () => "D")
      //
      .with(["7", ChordType.Major], () => "D")
      .with(["7", ChordType.Minor], () => "S")
      .with(["7", ChordType.Diminished], () => "D")
      .with(["7", ChordType.Augmented], () => "D")
      //
      .exhaustive()
  );
}
