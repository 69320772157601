/* eslint-disable max-lines */
/* eslint-disable unicorn/no-null */
const ast = [
  {
    chordBlocks: [
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Am",
              },
            },
            denominator: null,
            metaInfos: [
              {
                type: "key",
                value: "C",
              },
            ],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Am",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Am",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "E",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Em",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
      {
        type: "br",
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Am",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Am",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Am",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Am",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
      {
        type: "br",
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Am",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "E",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Em",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Am",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "G",
                  chordType: "M",
                  extensions: [],
                },
                plain: "G",
              },
            },
            denominator: null,
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Am",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
    ],
    metaInfos: [
      {
        type: "section",
        value: "A",
      },
    ],
  },
  {
    chordBlocks: [
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Am",
              },
            },
            denominator: null,
            metaInfos: [
              {
                type: "key",
                value: "C",
              },
            ],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Am",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Am",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "E",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Em",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
      {
        type: "br",
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Am",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Am",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Am",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Am",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
      {
        type: "br",
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Am",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "E",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Em",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Am",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
      {
        type: "bar",
        value: [
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "G",
                  chordType: "M",
                  extensions: [],
                },
                plain: "G",
              },
            },
            denominator: null,
            metaInfos: [],
          },
          {
            chordExpression: {
              type: "chord",
              value: {
                detailed: {
                  accidental: null,
                  base: "A",
                  chordType: "m",
                  extensions: [],
                },
                plain: "Am",
              },
            },
            denominator: null,
            metaInfos: [],
          },
        ],
      },
    ],
    metaInfos: [
      {
        type: "section",
        value: "A'",
      },
    ],
  },
];
export default ast;
