const chordProgression = `
@section=A
[key=C]Am - Am - Am - Em
Am - Am - Am - Am
Am - Em - Am - G, Am

@section=A'
[key=C]Am - Am - Am - Em
Am - Am - Am - Am
Am - Em - Am - G, Am
`;

export default chordProgression;
